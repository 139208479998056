import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import actions from '../Dashboard/actions'
import {postRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, postCustomRequest, getRootReq, postRootReq, getBaseRequest, postBaseRequest} from '../../config/axiosClient'

import {topMiddleErrorAlert, topMiddleSuccessAlert} from '../Alert/apiSaga';
import { buildDateQuery } from '@components/config/helpers';

export const generic_error_message = 'Oops, something went wrong!';
export const getDash = (state) => state.dashboardReducer;
export const getAuth = (state) => state.authReducer;

function* setupDashboardData(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date
    } = action.payload

    const queryString = `get-dashboard-data?customer_id=${customer_id}&email=${email}&start_date=${start_date}&end_date=${end_date}`
    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.SETUP_DASHBOARD_DATA_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.SETUP_DASHBOARD_DATA_FAILURE});
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* setupDashboardDetails(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date,
      type,
      str = ""
    } = action.payload

    let queryString = `get-dashboard-dataV2?customer_id=${customer_id}&email=${email}&start_date=${start_date}&end_date=${end_date}&type=${type}`
    if(str != ""){
      queryString += `&brands=${str}`;
    }

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_SUCCESS`], payload: {data: response.data, type: type}})
  
    } catch (error) {
      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_FAILURE`], payload: {data: response.data, type: type}})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}

function* setupDashboardQuotes(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date,
      type,
      str = ""
    } = action.payload

    let queryString = `get-dashboard-dataV2?customer_id=${customer_id}&email=${email}&type=${type}`;
    if(str != ""){
      queryString += `&brands=${str}`;
    }
    queryString += buildDateQuery('&', start_date, end_date)

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_SUCCESS`], payload: {data: response.data, type: type}})
  
    } catch (error) {
      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_FAILURE`], payload: {data: response.data, type: type}})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  
function* setupDashboardOrders(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date,
      type,
      str = ""
    } = action.payload

    let queryString = `get-dashboard-dataV2?customer_id=${customer_id}&email=${email}&type=${type}`;

    if(str != ""){
      queryString += `&brands=${str}`;
    }

    queryString += buildDateQuery('&', start_date, end_date)


    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_SUCCESS`], payload: {data: response.data, type: type}})
  
    } catch (error) {
      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_FAILURE`], payload: {data: response.data, type: type}})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  
function* setupDashboardAddresses(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date,
      type,
      str = ""
    } = action.payload

    let queryString = `get-dashboard-dataV2?customer_id=${customer_id}&email=${email}&type=${type}`
    if(str != ""){
      queryString += `&brands=${str}`;
    }
    queryString += buildDateQuery('&', start_date, end_date)

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_SUCCESS`], payload: {data: response.data, type: type}})
  
    } catch (error) {
      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_FAILURE`], payload: {data: response.data, type: type}})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  
function* setupDashboardJobs(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date,
      type,
      str = ""
    } = action.payload

    let queryString = `get-dashboard-dataV2?customer_id=${customer_id}&email=${email}&type=${type}`;
    if(str != ""){
      queryString += `&brands=${str}`;
    }
    queryString += buildDateQuery('&', start_date, end_date)
    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_SUCCESS`], payload: {data: response.data, type: type}})
  
    } catch (error) {
      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_FAILURE`], payload: {data: response.data, type: type}})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  
function* setupDashboardCredits(action) {
    const {
      customer_id,
      email,
      start_date,
      end_date,
      type,
      str = ""
    } = action.payload

    let queryString = `get-dashboard-dataV2?customer_id=${customer_id}&email=${email}&type=${type}`
    if(str != ""){
      queryString += `&brands=${str}`;
    }
    queryString += buildDateQuery('&', start_date, end_date)
    
    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_SUCCESS`], payload: {data: response.data, type: type}})
  
    } catch (error) {
      yield put({type: actions[`SETUP_DASHBOARD_${action.payload.type.toUpperCase()}_FAILURE`], payload: {data: response.data, type: type}})
      yield call(topMiddleErrorAlert, generic_error_message)
    }
}
  


function* getQuoteByID(action) {
    const {
      id,
      customer_id,
      quote_type,
    } = action.payload

    const queryString = `get-quote?id=${id}&customer_id=${customer_id}&quote_type=${quote_type}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.DASHBOARD_GET_QUOTE_BY_ID_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.DASHBOARD_GET_QUOTE_BY_ID_FAILURE});
      yield call(topMiddleErrorAlert, generic_error_message)
    }
  }
  

function* getOrderByID(action) {
    const {
      id,
      customer_id,
    } = action.payload

    const queryString = `get-order?id=${id}&customer_id=${customer_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.DASHBOARD_GET_ORDER_BY_ID_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.DASHBOARD_GET_ORDER_BY_ID_FAILURE});
      yield call(topMiddleErrorAlert, generic_error_message)
    }
  }

  function* getSingleQuote(action) {
    const {
      quote_id,
      customer_id,
    } = action.payload
    
    const queryString = `dashboard/quote/get-single-quote?quote_id=${quote_id}&customer_id=${customer_id}`;

    try {
      const response = yield call(() => getBaseRequest(queryString)); 

      if(response.data.status == 'failed'){
        yield call(topMiddleErrorAlert, response.data.message)
      }

      yield put({type: actions.GET_SINGLE_QUOTE_DETAILS_SUCCESS, payload: response.data})
  
    } catch (error) {
      yield put({type: actions.GET_SINGLE_QUOTE_DETAILS_FAILURE});
      yield call(topMiddleErrorAlert, generic_error_message)
    }
  }

  function* updateQuote(action) {
 
      const queryString = `dashboard/quote/update-quote`;
  
      try {
        const response = yield call(() => postBaseRequest(queryString, action.payload)); 
  
        if(response.data.status == 'failed'){
          yield call(topMiddleErrorAlert, response.data.message)
        }
  
        yield put({type: actions.DASHBOARD_UPDATE_QUOTE_SUCCESS, payload: response.data})
        yield call(topMiddleSuccessAlert, response.data.message)
    
      } catch (error) {
        yield put({type: actions.DASHBOARD_UPDATE_QUOTE_FAILURE});
        yield call(topMiddleErrorAlert, generic_error_message)
    }
}
    

function* getVehicleSizes(action) {
  const queryString = `dashboard/quote/get-vehicle-sizes`;

  try {
    const response = yield call(() => getBaseRequest(queryString)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.DASHBOARD_GET_VEHICLE_SIZES_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.DASHBOARD_GET_VEHICLE_SIZES_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* getAuditTrail(action) {

  
  const queryString = `dashboard/quote/get-audit-trail?quote_id=${action.payload.id}`;

  try {
    const response = yield call(() => getBaseRequest(queryString)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.DASHBOARD_GET_AUDIT_TRAIL_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.DASHBOARD_GET_AUDIT_TRAIL_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* getSnapshots(action) { 
  const queryString = `dashboard/quote/get-snapshots`;

  try {
    const response = yield call(() => postBaseRequest(queryString, action.payload)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.DASHBOARD_BUILD_SNAPSHOTS_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.DASHBOARD_BUILD_SNAPSHOTS_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* handlePayment(action) {

  const {
    quote,
    ...rest
  } = action.payload;


  try {
    // const response = yield call(() => postBaseRequest('finalise-quote-payment', {quote: quote, paymentIntentId}) ); 

    // if(response.data.status == 'failed'){
    //   yield call(topMiddleErrorAlert, response.data.message)
    // }

    // yield put({type: actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS, payload: response.data})
    yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
      loading: true,
      make_payment: true,
      create_order: true,
      create_carriage: false,
    }})

    const response = yield call(() => postBaseRequest('dashboard/quote/finalise-quote-payment', {quote: quote, ...rest}) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }


    if(response.data.status == 'success'){
      const data = response.data.data;

      const carriageData = {
        quote_id: quote.details.QuoteID,
        payment_id: action.payload.paymentIntentId,
        order_id: data.order_id,
        carriages: data.carriages
      };

      yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
        make_payment: true,
        create_order: true,
        create_carriage: action.payload.passed_auth ? true : false,
        error: false,
        message: response.data.message
      }})


      if(action.payload.passed_auth){
        yield put({type: actions.QUOTE_CREATE_CARRIAGE, payload: carriageData})
        
      }
      else {
        yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
          make_payment: true,
          create_order: true,
          create_carriage: true,
          loading: false,
          complete: true,
          error: false,
        }})
        yield put({type: actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS, payload: response.data})

      }

    }
    else {
      yield call(paymentProcessError, response.data.message)
    }


  } catch (error) {
    console.log(error)
    yield call(paymentProcessError, generic_error_message)
  }
}

function* createCarriage(action) {

  try {
    const dash = yield select(getDash);
    const auth = yield select(getAuth);
   
      const response = yield call(() => postBaseRequest('dashboard/quote/create-carriage', {
        ...action.payload,
        quote: dash.quote.keys //grab the cart
      }) ); 

      if(response.data.status == 'failed'){
        yield call(paymentProcessError, response.data.message)
      }
      else {
        yield put({type: actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS, payload: response.data})
        yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
          make_payment: true,
          create_order: true,
          create_carriage: true,
          loading: false,
          complete: true,
          error: false,
        }})

      }


  } catch (error) {
    console.log(error)
    yield call(paymentProcessError, generic_error_message)
  }
}

function* handleBacs(action) {

  const {
    quote,
    ...rest
  } = action.payload;


  // $quote = $request->get('quote');
  // $payment_id = $request->get('paymentIntentId');
  // $quote_id = $request->get('quote_id');
  // $CommLog = $request->get('CommLog');
  // $payment_amount = $request->get('payment_amount');
  // $customer_id = $request->get('customer_id');


  try {
    const response = yield call(() => postBaseRequest('dashboard/quote/finalise-quote-payment', {quote: quote, ...rest}) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    // yield put({type: actions.DASHBOARD_QUOTE_HANDLE_BACS_SUCCESS, payload: response.data})

    if(response.data.status == 'success'){
      const data = response.data.data;

      const carriageData = {
        quote_id: quote.details.QuoteID,
        payment_id: false,
        order_id: data.order_id,
        carriages: data.carriages
      };

      yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
        make_payment: true,
        create_order: true,
        create_carriage: action.payload.passed_auth,
        error: false
      }})

      if(action.payload.passed_auth){
        yield put({type: actions.QUOTE_CREATE_CARRIAGE, payload: carriageData})
      }
      else {
        yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
          make_payment: true,
          create_order: true,
          create_carriage: true,
          loading: false,
          complete: true,
          error: false,
        }})
        yield put({type: actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_SUCCESS, payload: response.data})

      }
    }
    else {
      yield call(paymentProcessError, response.data.message)
    }

  } catch (error) {
    console.log(error)
    yield put({type: actions.DASHBOARD_QUOTE_HANDLE_BACS_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* createNewQuote(action) {
  try {
    let queryStr = `dashboard/quote/get-info-for-new-quote?customer_id=${action.payload.customer_id}`;
    if(action?.payload?.brand_code){
      queryStr += "&brand_code=" + action.payload.brand_code
    }

    if(action?.payload?.addon_data){
      queryStr += `&is_addon=${action.payload.addon_data.is_addon}&order_id=${action.payload.addon_data.order_id}`;
    }

    if(action?.payload?.extension_data){
      queryStr += `&is_extension=${action.payload.extension_data.is_extension}&order_id=${action.payload.extension_data.order_id}`;
    }

    if(action?.payload?.cancellation_data){
      queryStr += `&is_cancellation=${action.payload.cancellation_data.is_cancellation}&amount=${action.payload.cancellation_data.percentage}&order_id=${action.payload.cancellation_data.order_id}`;
    }

    if(action?.payload?.recharge_data){
      queryStr += `&is_recharge=${action.payload.recharge_data.is_recharge}&order_id=${action.payload.recharge_data.order_id}`;
    }

    const response = yield call(() => getBaseRequest(queryStr) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.CREATE_NEW_QUOTE_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.CREATE_NEW_QUOTE_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* createBlankCustomerQuote(action) {
  const {
    ContactName,
    CompanyName,
    Telephone,
    EmailAddress,
    PostCode
  } = action.payload;
  try {
    const queryBuilder =`ContactName=${ContactName}&Telephone=${Telephone}&EmailAddress=${EmailAddress}&PostCode=${PostCode}&CompanyName=${CompanyName}`
    const response = yield call(() => getBaseRequest(`agent/generate-new-quote?${queryBuilder}`) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.CREATE_NEW_QUOTE_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.CREATE_NEW_QUOTE_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* getCustomerQuotes(action) {
  try {
    const {
      customer_id,
      email,
      start_date,
      end_date,
    } = action.payload
    const response = yield call(() => getBaseRequest(`dashboard/quote/get-customer-quotes?customer_id=${customer_id}&email=${email}&start_date=${start_date}&end_date=${end_date}`) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.GET_CUSTOMER_QUOTES_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.GET_CUSTOMER_QUOTES_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* getCustomerOrders(action) {
  try {
    const {
      customer_id,
      email,
      start_date,
      end_date,
    } = action.payload
    const response = yield call(() => getBaseRequest(`dashboard/quote/get-customer-orders?customer_id=${customer_id}&email=${email}&start_date=${start_date}&end_date=${end_date}`) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.GET_CUSTOMER_ORDERS_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.GET_CUSTOMER_ORDERS_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* getCustomerAddresses(action) {
  try {
    const {
      customer_id,
    } = action.payload
    const response = yield call(() => getBaseRequest(`dashboard/addresses/get-list-of-addresses?customer_id=${customer_id}`) ); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.GET_ADDRESSES_SUCCESS, payload: response.data})

  } catch (error) {
    yield put({type: actions.GET_ADDRESSES_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* editCustomerAddress(action) {
  try {
 
    const response = yield call(() => postBaseRequest(`dashboard/addresses/edit-customer-address`, action.payload)); 
    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
      yield put({type: actions.EDIT_ADDRESS_FAILURE, payload: response.data});
    }
    else {
      yield put({type: actions.EDIT_ADDRESS_SUCCESS, payload: {
        data: response.data,
        index: action?.payload?.index || 0
      }})
      yield call(topMiddleSuccessAlert, response.data.message)
    }


  } catch (error) {
    console.log(error)
    yield put({type: actions.EDIT_ADDRESS_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* createCustomerAddress(action) {
  try {
 
    const response = yield call(() => postBaseRequest(`dashboard/addresses/create-customer-address`, action.payload)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
      yield put({type: actions.NEW_ADDRESS_FAILURE, payload: response.data});
    }
    else {
      yield put({type: actions.NEW_ADDRESS_SUCCESS, payload: response.data})
      yield call(topMiddleSuccessAlert, response.data.message)
    }


  } catch (error) {
    console.log(error)
    yield put({type: actions.NEW_ADDRESS_FAILURE, payload: []});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}
function* copyCustomerAddress(action) {
  try {
 
    const response = yield call(() => postBaseRequest(`dashboard/addresses/copy-customer-address`, action.payload)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
      yield put({type: actions.COPY_ADDRESS_FAILURE, payload: response.data});
    }
    else {
      yield put({type: actions.COPY_ADDRESS_SUCCESS, payload: response.data})
      yield call(topMiddleSuccessAlert, response.data.message)
    }


  } catch (error) {
    console.log(error)
    yield put({type: actions.NEW_ADDRESS_FAILURE, payload: []});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* quoteOrderSendEmail(action) {
  try {

    const response = yield call(() => postRequest(`universal-template/send-${action.payload.data.type}-email`, action.payload)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
      yield put({type: actions.QUOTE_ORDER_SEND_EMAIL_FAILURE, payload: {...response.data, type: action.payload.type}});
    }
    else {
      yield put({type: actions.QUOTE_ORDER_SEND_EMAIL_SUCCESS, payload: {...response.data, type: action.payload.type}})
      yield call(topMiddleSuccessAlert, response.data.message)
    }


  } catch (error) {
    console.log(error)
    yield put({type: actions.QUOTE_ORDER_SEND_EMAIL_FAILURE, payload: []});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* getSingleOrderByID(action){
  const {
    order_id,
  } = action.payload
  
  const queryString = `dashboard/order/get-single-order?order_id=${order_id}`;

  try {
    const response = yield call(() => getBaseRequest(queryString)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.GET_SINGLE_ORDER_BY_ID_SUCCESS, payload: response.data})

  } catch (error) {
    console.log(error)
    yield put({type: actions.GET_SINGLE_ORDER_BY_ID_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* setQuoteBrandData(action){
  
  const queryString = `dashboard/brand/get-brand-data?brand_id=${action.payload}`;

  try {
    const response = yield call(() => getBaseRequest(queryString)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.SET_QUOTE_BRAND_DATA_SUCCESS, payload: response.data})

  } catch (error) {
    console.log(error)
    yield put({type: actions.SET_QUOTE_BRAND_DATA_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* setOrderBrandData(action){
  
  const queryString = `dashboard/brand/get-brand-data?brand_id=${action.payload}`;

  try {
    const response = yield call(() => getBaseRequest(queryString)); 

    if(response.data.status == 'failed'){
      yield call(topMiddleErrorAlert, response.data.message)
    }

    yield put({type: actions.SET_QUOTE_BRAND_DATA_SUCCESS, payload: response.data})

  } catch (error) {
    console.log(error)
    yield put({type: actions.SET_QUOTE_BRAND_DATA_FAILURE});
    yield call(topMiddleErrorAlert, generic_error_message)
  }
}

function* paymentProcessError(error) {
  yield put({type: actions.DASHBOARD_QUOTE_HANDLE_PAYMENT_FAILURE});
  yield call(topMiddleErrorAlert, error)
  yield put({type: actions.QUOTE_UPDATE_PAYMENT_STATUS, payload: {
    make_payment: false,
    create_order: false,
    create_carriage: false,
    loading: false,
    error: true
  }})
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SETUP_DASHBOARD_DATA, setupDashboardData),
    takeLatest(actions.SETUP_DASHBOARD_DETAILS, setupDashboardDetails),
    takeLatest(actions.SETUP_DASHBOARD_QUOTES, setupDashboardQuotes),
    takeLatest(actions.SETUP_DASHBOARD_ORDERS, setupDashboardOrders),
    takeLatest(actions.SETUP_DASHBOARD_ADDRESSES, setupDashboardAddresses),
    takeLatest(actions.SETUP_DASHBOARD_JOBS, setupDashboardJobs),
    takeLatest(actions.SETUP_DASHBOARD_CREDITS, setupDashboardCredits),
    takeLatest(actions.DASHBOARD_GET_QUOTE_BY_ID, getQuoteByID),
    takeLatest(actions.DASHBOARD_GET_ORDER_BY_ID, getOrderByID),
    takeLatest(actions.GET_SINGLE_QUOTE_DETAILS, getSingleQuote),
    takeLatest(actions.DASHBOARD_UPDATE_QUOTE, updateQuote),
    takeLatest(actions.DASHBOARD_GET_VEHICLE_SIZES, getVehicleSizes),
    takeLatest(actions.DASHBOARD_GET_AUDIT_TRAIL, getAuditTrail),
    takeLatest(actions.DASHBOARD_BUILD_SNAPSHOTS, getSnapshots),
    takeLatest(actions.DASHBOARD_QUOTE_HANDLE_PAYMENT, handlePayment),
    takeLatest(actions.DASHBOARD_QUOTE_HANDLE_BACS, handleBacs),
    takeLatest(actions.QUOTE_CREATE_CARRIAGE, createCarriage),
    takeLatest(actions.CREATE_NEW_QUOTE, createNewQuote),
    takeLatest(actions.CREATE_BLANK_CUSTOMER_QUOTE, createBlankCustomerQuote),
    takeLatest(actions.GET_CUSTOMER_QUOTES, getCustomerQuotes),
    takeLatest(actions.GET_CUSTOMER_ORDERS, getCustomerOrders),
    takeLatest(actions.GET_ADDRESSES, getCustomerAddresses),
    takeLatest(actions.EDIT_ADDRESS, editCustomerAddress),
    takeLatest(actions.NEW_ADDRESS, createCustomerAddress),
    takeLatest(actions.COPY_ADDRESS, copyCustomerAddress),
    takeLatest(actions.QUOTE_ORDER_SEND_EMAIL, quoteOrderSendEmail),
    takeLatest(actions.GET_SINGLE_ORDER_BY_ID, getSingleOrderByID),
    takeLatest(actions.SET_QUOTE_BRAND_DATA, setQuoteBrandData),
    takeLatest(actions.SET_ORDER_BRAND_DATA, setOrderBrandData),
    // takeLatest(actions.CONVERT_QUOTE, convertQuote)
  ]);
}
